<template>
  <multiselect
    :filterable="true"
    :options="options"
    v-model="selected"
    :custom-label="label"
    :placeholder="placeholder"
    :disabled="disabled"
    @input="handleInput"
  />
</template>

<script>
import vSelect from "vue-select";
export default {
  components: {
    vSelect,
  },
  props: {
    multiple: {
      type: Boolean,
      default: false,
    },
    draw: {
      type: Number,
    },
    placeholder: {
      type: String
    },
    value: {
      type: Object,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      baseUrl: "/api/wms/v1/master/warehouses",
      options: [],
      selected: this.value
    };
  },
  methods: {
    get() {
      this.$http
        .get(this.baseUrl, {
          params: {
            order: "name",
            sort: "asc",
            by_personal: 1,
          }
        })
        .then(resp => {
          if (resp.code === 200) {
            resp.data.records.map(function (x) {
              return (x.label = x.code + " - " + x.name);
            });

            this.options = resp.data.records;
            if (this.value) {
              this.selected = this.options.find(option => option.id === this.value.id);
            }
          } else {
            this.options = []
            console.log(resp.data);
          }
        });
    },
    label(option) {
      return option.label;
    },
    handleInput(value) {
      this.$emit('input', value);
      this.$emit('selected', value);
    }
  },
  watch: {
    selected(newVal) {
      this.$emit('input', newVal);
    },
    value(newVal) {
      this.selected = newVal;
    }
  },
  mounted() {
    this.get();
  }
};
</script>
